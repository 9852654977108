import * as Msal from "msal";
import React from "react";
import LocalStorage from "@appypay/common/utils/localStorage";

const state = {
  noScopes: false,
  launchApp: null,
  idToken: null,
  accessToken: null,
  userName: "",
};

let appConfig = {
  instance: null,
  tenant: null,
  signInPolicy: null,
  resetPolicy: null,
  applicationId: null,
  cacheLocation: null,
  scopes: [],
  redirectUri: null,
  postLogoutRedirectUri: null,
  validateAuthority: null,
  silentLoginOnly: false,
  language: "pt-PT",
};

function loggerCallback(logLevel, message) {
  console.log(message);
}

function loginAndAcquireToken(successCallback, reset = false) {
  const localMsalApp = window.msal;

  if (!reset && localStorage.getItem("reset-done")) {
    localStorage.clear();
    sessionStorage.clear();
    function clearAllCookies() {
      const cookies = document.cookie.split(";");
      for (let cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    }
    clearAllCookies();
    window.location.reload();
    return;
  }

  let user = localMsalApp.getUser(appConfig.scopes);

  if (!user) {
    // user is not logged in
    if (state.noScopes) {
      // no need of access token
      if (appConfig.silentLoginOnly) {
        // on silent mode we call error app
        if (state.errorApp) state.errorApp();
      }
      // just redirect to login page
      else
        localMsalApp.loginRedirect(
          appConfig.scopes,
          `ui_locales=${appConfig.language}`
        );
    } else {
      // try to get token from SSO session
      localMsalApp
        .acquireTokenSilent(
          appConfig.scopes,
          null,
          null,
          "&login_hint&domain_hint=organizations"
        )
        .then(
          (accessToken) => {
            state.accessToken = accessToken;
            user = localMsalApp.getUser(appConfig.scopes);
            state.idToken = user.idToken;
            state.userName = user.name;
            if (state.launchApp) {
              state.launchApp();
            }
            if (successCallback) {
              successCallback();
            }
          },
          (error) => {
            if (error) {
              if (appConfig.silentLoginOnly) state.errorApp();
              else
                localMsalApp.loginRedirect(
                  appConfig.scopes,
                  `ui_locales=${appConfig.language}`
                );
            }
          }
        );
    }
  } else {
    state.idToken = user.idToken;
    state.userName = user.name;
    if (state.noScopes) {
      if (state.launchApp) {
        state.launchApp();
      }
      if (successCallback) {
        successCallback();
      }
    } else {
      localMsalApp.acquireTokenSilent(appConfig.scopes).then(
        (accessToken) => {
          state.accessToken = accessToken;
          if (state.launchApp) {
            state.launchApp();
          }
          if (successCallback) {
            successCallback();
          }
        },
        (error) => {
          if (error) {
            localMsalApp.acquireTokenRedirect(appConfig.scopes);
          }
        }
      );
    }
  }
}

const logger = new Msal.Logger(loggerCallback, {
  level: Msal.LogLevel.Warning,
});

function redirect() {
  const localMsalApp = window.msal;
  const instance = appConfig.instance
    ? appConfig.instance
    : "https://login.microsoftonline.com/tfp/";
  const authority = `${instance}${appConfig.tenant}/${appConfig.resetPolicy}`;
  localMsalApp.authority = authority;
  loginAndAcquireToken(null, true);
  localStorage.setItem("reset-done", true);
}

function authCallback(errorDesc, token, error) {
  if (errorDesc && errorDesc.indexOf("AADB2C90118") > -1) {
    window.location.href = window.location.origin + "/reset-password";
  } else if (errorDesc) {
    console.error(`${error}:${errorDesc}`);
  }
}

const authentication = {
  initialize: (config) => {
    appConfig = config;
    const instance = config.instance
      ? config.instance
      : "https://login.microsoftonline.com/tfp/";
    const authority = `${instance}${config.tenant}/${config.signInPolicy}`;
    const validateAuthority =
      config.validateAuthority != null ? config.validateAuthority : true;
    const { scopes } = config;
    if (!scopes || scopes.length === 0) {
      state.noScopes = true;
    }
    state.scopes = scopes;

    // eslint-disable-next-line no-new
    new Msal.UserAgentApplication(
      config.applicationId,
      authority,
      authCallback,
      {
        logger,
        cacheLocation: config.cacheLocation,
        postLogoutRedirectUri: config.postLogoutRedirectUri,
        redirectUri: config.redirectUri,
        validateAuthority,
      }
    );
  },
  run: (launchApp, errorApp) => {
    state.launchApp = launchApp;
    if (errorApp) state.errorApp = errorApp;
    if (
      !window.msal.isCallback(window.location.hash) &&
      window.parent === window &&
      !window.opener
    ) {
      loginAndAcquireToken();
    }
  },
  required: (WrappedComponent, renderLoading) => {
    return class extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          signedIn: false,
          error: null,
        };
      }

      // eslint-disable-next-line react/no-deprecated
      componentWillMount() {
        if (localStorage.getItem("reset-initiated")) {
          localStorage.removeItem("reset-initiated");
          redirect();
        } else
          loginAndAcquireToken(() => {
            this.setState({
              // eslint-disable-next-line react/no-access-state-in-setstate
              ...this.state,
              signedIn: true,
            });
          });
      }

      render() {
        const { signedIn } = this.state;
        if (signedIn) {
          return <WrappedComponent {...this.props} />;
        }
        return typeof renderLoading === "function" ? renderLoading() : null;
      }
    };
  },
  signOut: () => {
    LocalStorage.remove("APPY_SELECTED_MERCHANT");
    window.msal.logout();
  },
  getIdToken: () => {
    return state.idToken;
  },
  getAccessToken: () => {
    return state.accessToken;
  },
  getUserName: () => {
    return state.userName;
  },
  goToLogin: () => {
    window.msal.logout();
  },
};

export default authentication;
